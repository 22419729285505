<template>
  <b-modal v-model="showModal" hide-footer title="Create Contract" modal-class="fade"
           class="v-modal-custom"
           centered>
    <form action="" @submit.prevent="create" class="text-dark">
      <div class="mb-3">
        <label for="contractName" class="form-label">Contract Name</label>
        <input v-model="name"
               type="text" class="form-control" id="contractName" placeholder="Contract name">
      </div>
      <div class="mb-3">
        <label for="contractCurrency" class="form-label">Currency</label>
        <select v-model="currency" class="form-select" id="contractCurrency">
          <option v-for="option in currencies" :key="`currency_${option.value}`" :value="option.value">
            {{ option.label }} ({{ option.icon }})
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label for="contractStartDate" class="form-label">Start Date</label>
        <input v-model="start_date" type="date"
               class="form-control" id="contractStartDate" rows="3" placeholder="Start Date">
      </div>
      <div class="mb-3">
        <label for="contractEndDate" class="form-label">Expire Date</label>
        <input v-model="end_date" type="date"
               class="form-control" id="contractEndDate" rows="3" placeholder="Start Date">
      </div>
      <div class="mb-3">
        <BAlert show variant="info" class="alert-label-icon label-arrow fade show" role="alert">
          <i class="ri-airplay-line label-icon"></i>
          <strong>Info</strong> - Only orders created in <b>{{ currency }}</b> are eligible to generate invoices using
          this contract.
        </BAlert>
      </div>
      <div class="text-end">
        <button v-if="!isFormValid" type="submit" class="btn btn-secondary" disabled>Create contract</button>
        <button v-if="isFormValid" type="submit" class="btn btn-secondary">Create contract</button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {createContract} from "@/api/client/ClientApi.js"
import Swal from "sweetalert2";

export default {
  emits: ['create'],
  name: "contract_create",
  props: {
    currencies: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      name: '',
      currency: 'USD',
      start_date: '',
      end_date: '',
      showModal: false,
    }
  },
  computed: {
    isFormValid() {
      return this.name !== '' && this.start_date && this.end_date
    }
  },
  methods: {
    async create() {
      try {
        let response = await createContract({
          company_slug: this.$route.params.slug,
          name: this.name,
          currency: this.currency,
          start_date: this.start_date,
          expire_date: this.end_date,
        })
        if (response.ok) {
          try {
            document.querySelector('#CreateContractModal .btn-close').click()
          } catch {
            console.log('modal not found')
          }
          this.name = ''
          this.start_date = ''
          this.end_date = ''
          this.$emit('create')
          this.showModal = false
          await Swal.fire({
            icon: 'success',
            title: 'Contract created successfully',
            showConfirmButton: false,
            timer: 2000
          })
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: response.data.message || 'Something went wrong',
          })
        }
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },
    selectedFile(event) {
      this.file = event.target.files[0];
    },
    deleteRecord() {
      this.file = null
      document.querySelector('#actFile').value = ''
    },
    openModal() {
      this.showModal = true
    }
  },
}
</script>

<style scoped>

</style>