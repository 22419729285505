<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "OrderSMGS",
  props: {
    order_number: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      selectedFile: null,
      error: "",
      loading: false,
      showModal: false,
      showEditModal: false,
      name: '',
      comment: '',
      order_smgs_list: [],

      selectedDoc: null,

      modalAction: 'create',

      toast: Swal.mixin({
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    }
  },
  methods: {
    async getOrderSMGS() {
      try {
        let response = await axios.get(`/order/order_documents/${this.order_number}/`)
        this.order_smgs_list = response.data;
      } catch {
        alert("Could not load smgs list for this order")
      }
    },
    clearSelectedFile() {
      this.selectedFile = null;
      let input = document.getElementById('order_smgs_input')
      input.value = "";
      this.name = "";
      this.comment = "";
      this.modalAction = 'create'
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.selectedFile = file;
      this.showModal = true
      this.name = file.name;
      this.comment = ``;
    },
    async uploadFile() {

      if (this.loading) return;

      if (!this.selectedFile) {
        this.error = "Please upload a file first"
        return;
      }

      const formData = new FormData();
      formData.append("order_number", this.order_number);
      formData.append("name", this.name);
      formData.append("comment", this.comment);
      formData.append("file", this.selectedFile);

      const Toast = Swal.mixin({
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      try {
        this.loading = true
        const response = await axios.post("/order/order_documents/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        this.loading = false
        this.showModal = false

        if (response.status === 201) {
          await this.getOrderSMGS()
          await Toast.fire({
            icon: 'success',
            title: 'Successfully!',
            text: 'File uploaded successfully'
          })
        } else {
          await Toast.fire({
            icon: 'error',
            title: 'Oops..',
            text: 'Failed to upload the file. Server error.'
          })
        }
      } catch (error) {
        this.loading = false
        await Toast.fire({
          icon: 'error',
          title: 'Oops..',
          text: 'Error uploading the file.'
        })
      }
    },

    downloadFile(doc_path) {
      let url = process.env.VUE_APP_ORDER_URL + doc_path
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file'));
    },

    async confirmDelete(doc) {
      await Swal.fire({
        position: "center",
        icon: "error",
        title: `${doc.name}`,
        text: doc.comment,
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Delete It',
        denyButtonText: 'Cancel',
        cancelButtonColor: 'transparent',
        focusConfirm: false,
        inputLabel: `Please, type DELETE to confirm`,
        input: 'text',
        inputPlaceholder: `DELETE`,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value.toLowerCase() === 'delete') {
              resolve(this.deleteDocument(doc))
            } else {
              resolve('Confirmation failed!)')
            }
          })
        }
      });
    },

    async deleteDocument(doc) {
      try {
        await axios.delete(`/order/order_documents/${doc.id}/delete/`, {})
        await this.getOrderSMGS();
        await this.toast.fire({
          icon: 'success',
          title: 'Successful!',
          text: 'Document deleted successfully'
        })
      } catch {
        await this.toast.fire({
          icon: 'error',
          title: 'Oops..',
          text: 'Error while deleting the document.'
        })
      }
    },

    openModalToEdit(doc) {
      this.modalAction = 'update'
      this.selectedDoc = doc
      this.showEditModal = true;
    },
    openModalToCreate() {
      this.modalAction = 'create'
      this.showModal = true;
    },

    async updateDocument(doc) {
      try {
        this.loading = true
        await axios.put(`/order/order_documents/${doc.id}/update/`, {
          name: doc.name,
          comment: doc.comment,
        })
        this.loading = false
        await this.getOrderSMGS();
        await this.toast.fire({
          icon: 'success',
          title: 'Successful!',
          text: 'Document updated successfully'
        })
      } catch {
        this.loading = false
        await this.toast.fire({
          icon: 'error',
          title: 'Oops.',
          text: 'Error while updating the document'
        })
      }
    }
  },
  watch: {
    showModal(newVal) {
      if (newVal === false) {
        this.clearSelectedFile()
      }
    },
    showEditModal(newVal) {
      if (newVal === false) {
        this.clearSelectedFile()
      }
    }
  },
  mounted() {
    this.getOrderSMGS();
  }
}
</script>

<template>

  <b-modal v-model="showModal" title="Upload document" centered hide-footer :hide-header-close="loading"
           :no-close-on-backdrop="loading">

    <BAlert v-if="error" show variant="warning" class="border-0 text-start" role="alert">
      <b>{{ error }}</b>
    </BAlert>

    <div>
      <input
          id="order_smgs_input"
          type="file"
          class="form-control"
          @change="onFileChange"
          :disabled="loading"
      />
    </div>

    <form v-if="selectedFile" @submit.prevent="uploadFile()">
      <b-row gutter-y="3" class="mt-3 border-top">
        <b-col cols="12">
          <label class="form-label">Title</label>
          <input :disabled="loading" v-model="name" class="form-control" placeholder="Title" required>
        </b-col>
        <b-col cols="12">
          <label class="form-label">Comment</label>
          <textarea :disabled="loading" v-model="comment" class="form-control" placeholder="Leave your comments here..."
                    rows="4" required></textarea>
        </b-col>
        <b-col cols="12">
          <b-button :type="loading ? 'button' : 'submit'" variant="success" class="w-100" :disabled="loading">
            Upload
            <i class="mdi" :class="{
            'mdi-upload': !loading,
            'mdi-loading mdi-spin': loading
          }"></i>
          </b-button>
        </b-col>
      </b-row>
    </form>
  </b-modal>

  <b-modal v-model="showEditModal" title="Edit document" centered hide-footer :hide-header-close="loading"
           :no-close-on-backdrop="loading">

    <form v-if="selectedDoc" @submit.prevent="updateDocument(selectedDoc)">
      <b-row gutter-y="3">
        <b-col cols="12">
          <label class="form-label">Title</label>
          <input :disabled="loading" v-model="selectedDoc.name" class="form-control" placeholder="Title" required>
        </b-col>
        <b-col cols="12">
          <label class="form-label">Comment</label>
          <textarea :disabled="loading" v-model="selectedDoc.comment" class="form-control"
                    placeholder="Leave your comments here..."
                    rows="4" required></textarea>
        </b-col>
        <b-col cols="12">
          <b-button :type="loading ? 'button' : 'submit'" variant="success" class="w-100" :disabled="loading">
            Save Document
            <i class="mdi" :class="{
            'mdi-content-save': !loading,
            'mdi-loading mdi-spin': loading
          }"></i>
          </b-button>
        </b-col>
      </b-row>
    </form>
  </b-modal>

  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5 class="mb-0">Order Documents</h5>
      <b-button @click="openModalToCreate()" variant="outline-success">Select documents</b-button>
    </div>
    <div class="card-body text-center">

      <div v-if="order_smgs_list.length === 0" class="py-5">
        <h5 class="card-title">
          This order has no documents yet
        </h5>
        <p class="card-text text-muted">
          Select a document to upload it here
        </p>
      </div>

      <b-list-group flush>
        <template v-for="(doc, index) in order_smgs_list" :key="`doc_${index}`">
          <BListGroupItem class="d-flex justify-content-between align-items-center">
            <div class="text-start">
              <h5 class="fs-14 mb-1">{{ doc.name }}</h5>
              <span class="text-muted">{{ doc.comment || '--' }}</span>
            </div>
            <div class="d-flex align-items-center gap-2">
              <b-button @click="openModalToEdit(doc)" variant="outline-primary" class="fs-6 align-middle" size="sm">
                <i class="mdi mdi-pencil"></i>
              </b-button>
              <b-button @click="downloadFile(doc.file)" variant="outline-success" class="fs-6 align-middle" size="sm">
                <i class="mdi mdi-download"></i>
              </b-button>
              <b-button @click="confirmDelete(doc)" variant="outline-danger" class="fs-6 align-middle" size="sm">
                <i class="mdi mdi-trash-can"></i>
              </b-button>
            </div>
          </BListGroupItem>
        </template>
      </b-list-group>

    </div>
  </div>
</template>

<style scoped>

</style>