<script>
import {defineComponent} from 'vue'
import Counterparty from "./components/Counterparty.vue"
import store from "@/state/store";

export default defineComponent({
  name: "ActualCostTab",
  emits: ['update'],
  props: {
    order_counterparties: {
      type: Array,
      required: true,
      default: () => []
    },
    order_container_types: {
      type: Array,
      required: true,
      default: () => []
    },
    counterparty_list: {
      type: Array,
      required: true,
      default: () => []
    },
    category_list: {
      type: Array,
      required: true,
      default: () => []
    },
    order_currency: {
      type: String,
      required: true,
      default: () => 'USD'
    },
    order_currency_icon: {
      type: String,
      required: true,
      default: () => '$'
    },
    currency_conversion_rate: {
      type: Number,
      required: true,
      default: () => 1
    },
    currencies: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  components: {
    Counterparty,
  },
  data() {
    return {
      blacklisted_users_ids: [225]
    }
  },
  computed: {
    currencies_map() {
      try {
        return this.currencies.reduce((map, item) => {
          map[item.value] = item.icon;
          return map;
        }, {});
      } catch {
        return {}
      }
    },
    counterparties() {
      return this.order_counterparties
    },
    container_types() {
      return this.order_container_types
    },
    agreed_rate_sum() {
      try {
        return this.container_types.reduce((total, container_type) => {
          const containerSum = container_type.expanses.reduce((sum, expense) =>
              sum + parseFloat(expense.agreed_rate || 0), 0);
          return total + containerSum;
        }, 0)
      } catch {
        return 0
      }
    },
    additional_cost_sum() {
      try {
        return this.container_types.reduce((total, container_type) => {
          const containerSum = container_type.expanses.reduce((sum, expense) =>
              sum + parseFloat(expense.additional_cost || 0), 0);
          return total + containerSum;
        }, 0)
      } catch {
        return 0
      }
    },
    sum_total_expenses() {
      return this.counterparties.reduce(
          (total, counterparty) => total + parseFloat(this.sumCounterpartyExpenses(counterparty) * this.calculateConversionRate(counterparty.exchange_rate)), 0);
    },
    sum_profit() {
      let agreed_rates_sum = (this.agreed_rate_sum + this.additional_cost_sum) * this.currency_conversion_rate
      return agreed_rates_sum - this.sum_total_expenses
    },
    user() {
      return store.state.user
    }
  },
  methods: {
    updateCounterparty(counterparty) {
      console.log(counterparty)
    },
    formatPrice(price, currency_icon) {
      return this.currencies_map[currency_icon || this.order_currency] + ' ' + parseFloat(price || '0').toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
    calculateConversionRate(exchange_rate) {
      try {
        return (1 / parseFloat(exchange_rate))
      } catch {
        return 1
      }
    },
    sumCounterpartyExpenses(counterparty) {
      try {
        return this.container_types
            .flatMap(container_type => container_type.expanses)
            .flatMap(expense => expense.actual_costs)
            .filter(actual_cost => actual_cost.counterparty_id === counterparty.id)
            .reduce((sum, actual_cost) => sum + parseFloat(actual_cost.actual_cost || '0'), 0)
      } catch {
        return parseFloat('0')
      }
    },
    calculateContainerTotal(container) {
      try {
        if (!container.actual_costs) return 0;

        return container.actual_costs.reduce((total, cost) => {
          const counterparty = this.counterparties.find(i => i.id === cost.counterparty_id) || {};
          const actualCost = parseFloat(cost.actual_cost || 0);
          const exchangeRate = parseFloat(counterparty.exchange_rate || 1);

          return total + actualCost * this.calculateConversionRate(exchangeRate);
        }, 0);
      } catch {
        return 0;
      }
    },
    calculateContainerProfit(container) {
      if (container) {
        let agreed_rate_sum = parseFloat(container.agreed_rate) + parseFloat(container.additional_cost)
        return agreed_rate_sum * this.currency_conversion_rate - this.calculateContainerTotal(container)
      }
    },
  }
})
</script>

<template>
  <div class="tab-pane" id="preliminary_cost_tab" role="tabpanel">
    <div class="table-responsive table-card">
      <table class="table table-striped mb-0">
        <thead>
        <tr class="bg-light align-middle">
          <th class="text-center">#</th>
          <th class="text-center">Container</th>
          <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">Agreed rate</th>
          <th class="text-center">Additional Cost</th>
          <th class="text-center py-0 m-0" v-for="counterparty in counterparties"
              :key="`counterparty + ${counterparty.id}`"
              @click="updateCounterparty(counterparty)">
            <Counterparty
                @update="this.$emit('update')"
                :counterparty="counterparty"
                :counterparty_list="counterparty_list"
                :category_list="category_list"
            />
          </th>
          <th class="text-center">Agreed rate <br> + Additional Cost</th>
          <th class="text-center">Total Expenses</th>
          <th v-if="!blacklisted_users_ids.includes(user.id)" class="text-center">Profit</th>
        </tr>
        <tr class="bg-light align-middle fs-5">
          <th class="text-center">
          </th>
          <th class="text-center">
          </th>
          <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">
            {{ formatPrice(agreed_rate_sum) }}
          </th>
          <th class="text-center">
            {{ formatPrice(additional_cost_sum) }}
          </th>
          <th class="text-center m-0" v-for="party in counterparties"
              :key="party.id">
            {{ formatPrice(sumCounterpartyExpenses(party), party.currency) }}
          </th>
          <th class="text-center">
            {{ formatPrice(agreed_rate_sum + additional_cost_sum) }}
          </th>
          <th class="text-center">
            {{ formatPrice(sum_total_expenses, 'USD') }}
          </th>
          <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">

            {{ formatPrice(sum_profit, 'USD') }}
          </th>
        </tr>
        </thead>
        <tbody v-for="ctr_type in container_types" :key="ctr_type">
        <tr class="border-success">
          <th class="my-1 bg-transparent text-success"
              :colspan="blacklisted_users_ids.includes(user.id) ? 6 : 7 + counterparties.length">
            <div class="d-flex justify-content-start">
              {{ ctr_type.container_type }}
            </div>
          </th>
        </tr>
        <tr class="align-middle text-center"
            v-for="(container, i) in ctr_type.expanses" :key="container"
            @mouseover="container.is_hovered = true"
            @mouseleave="container.is_hovered = false"
        >
          <th>
            {{ i + 1 }}
          </th>
          <td class="text-center" style="max-width: 125px">
            {{ container.container ? container.container.name : '--' }}
          </td>
          <td class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">
            <input type="number" class="form-control form-control-sm w-75 mx-auto"
                   :value="container.agreed_rate"
                   readonly
            >
          </td>
          <td class="text-center">
            <input type="number" class="form-control form-control-sm w-75 mx-auto"
                   :value="container.additional_cost"
                   readonly
            >
          </td>
          <td class="text-center"
              v-for="counterparty in ctr_type.container_preliminary_costs"
              :key="counterparty.id" style="max-width: 65px">
            <input type="number" class="form-control form-control-sm w-75 mx-auto"
                   :value="counterparty.preliminary_cost"
                   readonly
            >
          </td>
          <td>
            {{ formatPrice(parseFloat(container.agreed_rate) + parseFloat(container.additional_cost)) }}
          </td>
          <td>
            {{ formatPrice(calculateContainerTotal(container), 'USD') }}
          </td>
          <td v-if="!blacklisted_users_ids.includes(user.id)">
            {{ formatPrice(calculateContainerProfit(container), 'USD') }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>

</style>