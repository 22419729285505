<script>
import {defineComponent} from 'vue'
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import {orderComputed, ordersMehtods} from "@/state/helpers";

export default defineComponent({
  name: "AutoComplete",
  emits: ["autocompleted"],
  data() {
    return {
      options: [],
      selected: null
    }
  },
  props: {
    perform: {
      type: String,
      required: false,
      default: 'create'
    }
  },
  computed: {
    ...orderComputed,
    autocomplete: {
      get() {
        return this.$store ? this.$store.state.orders.autocomplete : ''
      },
      set(value) {
        this.setAutoComplete(value)
      }
    },
    order_number: {
      get() {
        return this.$store ? this.$store.state.orders.autocomplete.order_number : null
      },
      set(value) {
        this.$store.state.orders.autocomplete.order_number = value
      }
    },
    lot_number: {
      get() {
        return this.$store ? this.$store.state.orders.lot_number : null
      },
      set(value) {
        this.setLotNumber(value)
      }
    },
    sending_type: {
      get() {
        return this.$store ? this.$store.state.orders.sending_type : null
      },
      set(value) {
        this.setSendingType(value)
      }
    },
    position: {
      get() {
        return this.$store ? this.$store.state.orders.position : null
      },
      set(value) {
        this.setPosition(value)
      }
    },
    shipper: {
      get() {
        return this.$store ? this.$store.state.orders.shipper : null
      },
      set(value) {
        this.setShipper(value)
      }
    },
    consignee: {
      get() {
        return this.$store ? this.$store.state.orders.consignee : null
      },
      set(value) {
        this.setConsignee(value)
      }
    },
    order_type: {
      get() {
        return this.$store ? this.$store.state.orders.order_type : null
      },
      set(value) {
        this.setOrderType(value)
      }
    },
    shipment_status: {
      get() {
        return this.$store ? this.$store.state.orders.shipment_status : null
      },
      set(value) {
        this.setShipmentStatus(value)
      }
    },
    payment_status: {
      get() {
        return this.$store ? this.$store.state.orders.payment_status : null
      },
      set(value) {
        this.setPaymentStatus(value)
      }
    },
    date: {
      get() {
        return this.$store ? this.$store.state.orders.date : null
      },
      set(value) {
        this.setDate(value)
      }
    },
    departure_id: {
      get() {
        return this.$store ? this.$store.state.orders.departure_id : null
      },
      set(value) {
        this.setDepartureId(value)
      }
    },
    destination_id: {
      get() {
        return this.$store ? this.$store.state.orders.destination_id : null
      },
      set(value) {
        this.setDestinationId(value)
      }
    },
    border_crossing: {
      get() {
        return this.$store ? this.$store.state.orders.border_crossing : null
      },
      set(value) {
        this.setBorderCrossing(value)
      }
    },
    conditions_of_carriage: {
      get() {
        return this.$store ? this.$store.state.orders.conditions_of_carriage : null
      },
      set(value) {
        this.setConditionsOfCarriage(value)
      }
    },
    rolling_stock: {
      get() {
        return this.$store ? this.$store.state.orders.rolling_stock : null
      },
      set(value) {
        this.setRollingStock(value)
      }
    },
    departure: {
      get() {
        return this.$store ? this.$store.state.orders.departure : null
      },
      set(value) {
        this.setDeparture(value)
      }
    },
    destination: {
      get() {
        return this.$store ? this.$store.state.orders.destination : null
      },
      set(value) {
        this.setDestination(value)
      }
    },
    company_id: {
      get() {
        return this.$store ? this.$store.state.orders.company_id : null
      },
      set(value) {
        this.setCompanyId(value)
      }
    },
    product_id: {
      get() {
        return this.$store ? this.$store.state.orders.product_id : null
      },
      set(value) {
        this.setProductId(value)
      }
    },
    currency: {
      get() {
        return this.$store ? this.$store.state.orders.currency : 'USD'
      },
      set(value) {
        this.setCurrency(value)
      }
    },
    exchange_rate: {
      get() {
        return this.$store ? this.$store.state.orders.exchange_rate : 0
      },
      set(value) {
        this.setExchangeRate(value)
      }
    },
    comment: {
      get() {
        return this.$store ? this.$store.state.orders.comment : null
      },
      set(value) {
        this.setComment(value)
      }
    },
    counterparties: {
      get() {
        return this.$store ? this.$store.state.orders.counterparties : null
      },
      set(value) {
        this.addCounterparty(value)
      }
    },
    pre_order: {
      get() {
        return this.$store ? this.$store.state.orders.pre_order : ''
      },
      set(value) {
        this.$store.state.orders.pre_order = value
      },
    },

    favorite_order_numbers() {
      return JSON.parse(localStorage.getItem('autocompletes')) || []
    }
  },
  methods: {
    ...ordersMehtods,
    async searchOrders(query) {
      if (query.trim().length === 0) return
      let response = await axios.get(`/container_order/get_order_numbers/`, {
        params: {
          search: query.trim()
        }
      })
      this.options = response.data.container_orders.map((order_number) => {
        return {
          label: order_number,
          value: order_number
        }
      })
    },
    async getOrderByOrderNumber(order_number) {
      try {
        let response = await axios.get(`/container_order/get_auto_complete/${order_number}/`)
        return response.data
      } catch {
        return null
      }
    },
    async autocompleteOrder(number) {
      let order = await this.getOrderByOrderNumber(number)
      if (order) {

        this.fullClean()

        this.autocomplete = {
          departures: order.order.departure,
          destinations: order.order.destination,
          products: order.product,
          companies: order.order.company,
          order_number: number
        }

        this.options = [this.order_number]

        this.lot_number = order.order.lot_number
        this.sending_type = order.sending_type
        this.position = order.order.position
        this.shipper = order.order.shipper
        this.consignee = order.order.consignee
        this.order_type = order.order.type
        this.shipment_status = order.order.shipment_status
        this.payment_status = order.order.payment_status
        this.departure_id = order.order.departure.id
        this.destination_id = order.order.destination.id
        this.border_crossing = order.order.border_crossing
        this.conditions_of_carriage = order.order.conditions_of_carriage
        this.rolling_stock = order.order.rolling_stock
        this.departure = order.order.departure_country
        this.destination = order.order.destination_country
        this.company_id = order.order.company.id
        this.product_id = order.product.id
        this.comment = order.order.comment
        this.currency = order.order.currency || 'USD'
        this.exchange_rate = order.order.exchange_rate || 1

        if (this.perform === "update") {
          this.date = order.order.date
        }

        this.$store.state.orders.counterparties = []
        this.$store.state.orders.container_types = []

        order.order.counterparties.forEach((counterparty) => {
          let party = {...counterparty}
          this.addCounterparty({
            counterparty: {
              value: {...party.counterparty}.id,
              label: {...party.counterparty}.name,
            },
            categories: [...party.category].map(category => {
              let cat = {...category}
              return {
                value: {...cat}.id,
                label: {...cat}.name,
                price: '',
              }
            }),
            delete_is_confirmed: false,
          })
        })

        order.container_types.forEach(container_type => {
          let type = {...container_type}
          this.addContainerType({
            type: type.container_type,
            quantity: type.quantity,
            agreed_rate: type.agreed_rate,
            counterparties: []
          })
          type.container_preliminary_costs.forEach(i => {
            let list = this.$store.state.orders.container_types.find(a => a.type === type.container_type).counterparties
            let result = list.find(c => c.counterparty.value === i.counterparty.id)
            result.categories.find(c => c.value === i.category.id).price = i.preliminary_cost
          })
        })

        this.$emit("autocompleted", true)
      } else {
        this.selected = null
        await Swal.fire({
          icon: 'error',
          title: 'Try Again Later...',
          text: 'We couldn\'t complete your order!',
        })
      }
    }
  },
  components: {
    Multiselect
  },
  watch: {
    order_number(order_number) {
      if (order_number) {
        this.autocompleteOrder(order_number)
      }
    }
  },
  mounted() {
    if (this.order_number) {
      this.options = [this.order_number]
    }
  }
})
</script>

<template>
  <b-card no-body>
    <b-card-body v-if="pre_order && pre_order.type === ''">
      <h4 class="mb-3">
        <i class="mdi mdi-star-check h1 align-middle me-1 mb-0 text-warning"></i>
        AutoComplete
      </h4>
      <p class="fs-18 text-muted">
        Start typing anything you know about order to find it or just click on any of your favorite orders and let us do
        the rest
      </p>

      <div class="mb-4">
        <label class="form-label">Search orders</label>
        <Multiselect
            @search-change="searchOrders"
            :options="options" v-model="order_number"
            class="fs-24" :searchable="true" placeholder="Start typing ..."
        />
      </div>

      <label class="text-muted">Recent searches</label>

      <div class="d-flex justify-content-start gap-2" v-if="favorite_order_numbers.length > 0">
        <h5 class="my-0 cursor-pointer"
            v-for="number in favorite_order_numbers.slice(0, 5)"
            :key="`fav_order_${number}`"
            @click="autocompleteOrder(number)"
        >
          <span class="badge badge-gradient-secondary">
            {{ number }}
          </span>
        </h5>
      </div>
      <div class="d-flex justify-content-start gap-2" v-else>
        -
      </div>
    </b-card-body>
    <b-card-body v-else-if="pre_order && pre_order.type === 'code_order'">
      <h4 class="mb-3">
        <i class="mdi mdi-star-check h1 align-middle me-1 mb-0 text-warning"></i>
        AutoComplete
      </h4>
      <p class="fs-18 text-muted mb-0">
        AutoComplete is disabled for pre orders
      </p>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>