<script>
import Contracts from "@/views/pages/accounts/counterparties/tabs/Contracts.vue"
import Currencies from "@/core/Currencies";

export default {
  name: "OverviewComponent",
  data() {
    return {
      search_text: '',
      Currencies: Currencies
    }
  },
  props: {
    company_type: {
      type: String,
      required: true
    }
  },
  components: {
    Contracts
  },
}
</script>

<template>
  <Contracts :currencies="Currencies" :company_type="company_type"/>
</template>

<style scoped>

</style>