<script setup>
import {defineProps, onMounted, ref} from "vue";
import axios from "axios";
import Swal from "sweetalert2";

const props = defineProps({
  order_number: {
    type: Number,
    required: true,
  },
});

let booking_orders = ref([])

const getBookingOrders = async () => {
  try {
    let response = await axios.get(`/order/booking_order/${props.order_number}/list/`)
    return response.data
  } catch {
    return []
  }
}

const downloadFile = (file) => {
  let url = process.env.VUE_APP_ORDER_URL + file;
  fetch(url)
      .then((resp) => resp.blob())
      .then((blobobject) => {
        const blob = window.URL.createObjectURL(blobobject);
        const anchor = document.createElement("a");
        anchor.style.display = "none";
        anchor.href = blob;
        anchor.download = url.toString().split("/")[
        url.toString().split("/").length - 1
            ];
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(blob);
      })
      .catch(() => alert("An error in downloading the file sorry"));
}

const confirmDelete = async (doc) => {
  await Swal.fire({
    position: "center",
    icon: "error",
    title: `Deleting`,
    text: doc.file.split('/').pop(),
    showDenyButton: true,
    showConfirmButton: true,
    confirmButtonText: 'Yes, Delete It',
    denyButtonText: 'Cancel',
    cancelButtonColor: 'transparent',
    focusConfirm: false,
    inputLabel: `Please, type DELETE to confirm`,
    input: 'text',
    inputPlaceholder: `DELETE`,
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value.toLowerCase() === 'delete') {
          resolve(deleteBookingOrder(doc))
        } else {
          resolve('Confirmation failed!)')
        }
      })
    }
  });
}

const deleteBookingOrder = async (doc) => {
  try {
    await axios.delete(`/order/booking_order/${doc.id}/delete/`)
    booking_orders.value = await getBookingOrders()
    await Swal.fire({
      position: "center",
      icon: "success",
      title: "Successfully deleted",
    })
  } catch {
    await Swal.fire({
      position: "center",
      icon: "error",
      title: "Could not delete booking order",
      text: doc.file.split('/').pop(),
    })
  }
}

onMounted(async () => {
  booking_orders.value = await getBookingOrders()
})

</script>

<template>

  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5 class="mb-0">Booking Orders</h5>
      <router-link :to="{
        name: 'orders_create_booking_order',
        params: {
          id: props.order_number
        }
      }">
        <b-button variant="outline-success">
          <i class="bx bx-plus-medical me-1 align-middle"></i>
          Create New
        </b-button>
      </router-link>
    </div>
    <div class="card-body text-center">

      <div v-if="booking_orders.length === 0" class="py-5">
        <h5 class="card-title">
          This order has no orders yet
        </h5>
        <p class="card-text text-muted">
          Create a new booking-order to see it here
        </p>
      </div>

      <b-list-group flush>
        <template v-for="(doc, index) in booking_orders" :key="`doc_${index}`">
          <BListGroupItem class="d-flex justify-content-between align-items-center">
            <div class="text-start">
              <h5 class="fs-14 mb-1 text-capitalize">{{ doc.booking_type }}</h5>
              <span class="text-muted">{{ (doc.file || '--').split('/').pop() }}</span>
            </div>
            <div class="d-flex align-items-center gap-2">

              <router-link :to="{
                  name: 'orders_edit_booking_order',
                  params: {
                    id: props.order_number,
                    booking_order_id: doc.id
                  }
                }"
              >
                <b-button variant="outline-primary" class="fs-6 align-middle" size="sm">
                  <i class="mdi mdi-pencil"></i>
                </b-button>
              </router-link>
              <b-button v-if="doc.file" @click="downloadFile(doc.file)" variant="outline-success"
                        class="fs-6 align-middle" size="sm">
                <i class="mdi mdi-download"></i>
              </b-button>
              <b-button @click="confirmDelete(doc)" variant="outline-danger" class="fs-6 align-middle" size="sm">
                <i class="mdi mdi-trash-can"></i>
              </b-button>
            </div>
          </BListGroupItem>
        </template>
      </b-list-group>

    </div>
  </div>
</template>

<style scoped>

</style>