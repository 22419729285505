<script>
import {defineComponent} from 'vue'
import {getContractsByCompanySlug} from "@/api/client/ClientApi";
import ContractCreateModal from "@/views/pages/accounts/counterparties/modals/ContractCreate.vue";
import ContractUpdateModal from "@/views/pages/accounts/counterparties/modals/ContractUpdate.vue";
import moment from "moment";
import store from "@/state/store";

export default defineComponent({
  name: "Contracts",
  components: {
    ContractCreateModal,
    ContractUpdateModal,
  },
  props: {
    company_type: {
      type: String,
      required: true
    },
    currencies: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      isLoadingContracts: true,
      contracts: [],
      currentContract: {},
      mode: 'contracts',
      contractModeDetails: {},

      request: {
        status: 'loading',
        data: []
      },

      search_text: ''
    }
  },
  computed: {
    filteredContracts() {
      if (this.search_text === '') return this.contracts
      return this.contracts.filter(contract => contract.name.toLowerCase().includes(this.search_text.toLowerCase().trim()))
    },
    has_permission_for_contracts() {
      return store.state.user.pages.includes('counterparty_profile_contracts')
    },
  },
  methods: {
    async getContracts() {
      this.request.status = 'loading'
      try {
        let response = await getContractsByCompanySlug(this.$route.params.slug)
        this.contracts = response.ok ? response.data.results : []
        this.request.status = response.ok ? 'success' : 'error'
      } catch {
        this.request.status = 'error'
      }
    },
    onContractCreated() {
      this.getContracts()
    },
    formatDate(date) {
      return moment(date).format('DD MMM YYYY')
    },
    // setContractToLocalStorage(contract) {
    //   localStorage.setItem(`contract_${contract.slug}`, JSON.stringify(contract.name))
    // },
    calculatePayment(payment) {
      if (!payment) return 0
      return parseFloat(payment || 0)
    },
    totalBalance(payment, act_price) {
      if (!payment || !act_price) return 0
      return parseFloat((payment || 0) - (act_price || 0))
    },
  },
  mounted() {
    this.getContracts()
  }
})
</script>

<template>
  <div v-if="has_permission_for_contracts" class="mb-3">
    <b-row v-if="request.status === 'success'" class="gy-2 mb-2">
      <b-col>
        <b-card class="mb-0">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="d-flex justify-content-start align-items-center gap-3">
                <router-link v-if="this.$route.name === 'counterparty_profile_contracts'"
                             :to="{name: 'counterparty_profile_main'}">
                  <b-button variant="light" class="btn-border">
                    <i class="mdi mdi-keyboard-backspace align-bottom me-1"></i>
                    Back
                  </b-button>
                </router-link>
                <div>
                  <input v-model="search_text" class="form-control" placeholder="Search contracts">
                </div>
              </div>
            </div>
            <b-button variant="outline-secondary" @click="this.$refs.contractCreateModal.openModal()">
              <i class="ri-add-line align-bottom me-1"></i> Create Contract
            </b-button>
          </div>
        </b-card>
      </b-col>

      <template v-if="filteredContracts.length > 0">
        <b-col lg="12" v-for="(contract, index) of filteredContracts" :key="index" class="scale-animation">
          <b-card no-body class="contract-card mb-0"
                  @mouseover="contract.hovered = true"
                  @mouseleave="contract.hovered = false"
          >
            <b-card-body>
              <div class="d-lg-flex align-items-center">
                <div class="flex-shrink-0">
                  <div class="avatar-sm rounded bg-soft-secondary">
                    <i
                        :class="company_type === 'counterparty' ? {
                    'bx bx-folder-minus': contract.act_count === 0,
                    'mdi mdi-folder-outline': contract.act_count === 1,
                    'mdi mdi-folder-multiple': contract.act_count > 1,
                      } : 'mdi mdi-folder-outline'"
                        class="h-100 d-flex align-items-center justify-content-center fs-3 text-secondary"></i>
                  </div>
                </div>
                <div class="ms-3">
                  <h5 class="fs-16 mb-1">{{ contract.name }}</h5>
                  <p class="text-muted mb-0">
                    <i class="mdi mdi-clock-time-eight-outline fs-5 me-1"></i>
                    {{ formatDate(contract.start_date) }} -
                    {{ formatDate(contract.expire_date) }}
                  </p>
                </div>
                <div v-if="company_type === 'counterparty'" class="mx-auto" style="min-width: 150px">
                  <b-progress :value="(contract.completed_act_count / (contract.act_count || 1 )) * 100"
                              class="custom-progress"
                              variant="success">
                  </b-progress>
                  <p class="text-muted mb-0 mt-2">
                    {{ contract.completed_act_count }} of {{ contract.act_count }}
                    {{ contract.act_count > 1 ? 'acts' : 'act' }} completed
                  </p>
                </div>
                <div class="mx-auto">
                  <div class="d-flex justify-content-between gap-5 w-100">
                    <div class="p-2">
                      <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                          <h5 class="mb-0">
                            {{ contract.currency || '-USD-' }}
                          </h5>
                          <p class="text-muted mb-0">Currency</p>
                        </div>
                      </div>
                    </div>
                    <div class="p-2">
                      <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                          <h5 :class="{
                          'text-danger': totalBalance(contract.total_payment, company_type === 'counterparty' ?  contract.total_act_price : contract.total_invoice) < 0
                        }"
                              class="mb-0">$ {{
                              totalBalance(contract.total_payment, company_type === 'counterparty' ? contract.total_act_price : contract.total_invoice).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })
                            }}</h5>
                          <p class="text-muted mb-0">Total Balance</p>
                        </div>
                      </div>
                    </div>
                    <div class="p-2">
                      <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                          <h5 class="mb-0">$ {{
                              calculatePayment(contract.total_payment).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })
                            }}</h5>
                          <p class="text-muted mb-0">Total Payments</p>
                        </div>
                      </div>
                    </div>
                    <div class="p-2">
                      <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                          <h5 class="mb-0">$ {{
                              calculatePayment(company_type === 'counterparty' ? contract.total_act_price : contract.total_invoice).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })
                            }}</h5>
                          <p class="text-muted mb-0">
                            {{ company_type === 'counterparty' ? 'Total Act Price' : 'Total Invoices' }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex gap-3 flex-wrap ms-auto">
                  <router-link v-if="company_type === 'customer'"
                               :to="{
                    name: `customer_profile_contracts_orders`,
                    params: {contract_slug: contract.slug
                    }}"
                  >
                    <b-button :class="{'btn-border': contract.hovered}" variant="soft-secondary">
                      Orders
                    </b-button>
                  </router-link>
                  <router-link v-if="company_type === 'counterparty'"
                               :to="{
                    name: `counterparty_profile_contracts_acts`,
                    params: {contract_slug: contract.slug
                    }}"
                  >
                    <b-button :class="{'btn-border': contract.hovered}" variant="soft-secondary">
                      Acts
                    </b-button>
                  </router-link>
                  <router-link
                      :to="{
                    name:`${ this.company_type === 'counterparty' ?  'counterparty_profile_contracts_docs' : 'customer_profile_contracts_docs' }`,
                    params: {contract_slug: contract.slug}}">
                    <b-button :class="{'btn-border': contract.hovered}" variant="soft-secondary">Documents
                    </b-button>
                  </router-link>
                  <b-button
                      @click="this.$refs.contractUpdateModal.openModal(contract)"
                      :class="{'btn-border': contract.hovered}" variant="soft-success">
                    <i class="bx bx-pencil align-middle me-1"></i>
                    Edit
                  </b-button>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </template>
      <template v-else>
        <div class="col-12" v-if="search_text.trim() === ''">
          <b-card class="mb-0">
            <div class="d-flex justify-content-start align-items-center">
              <h5 class="mb-0">
                No contracts yet !
              </h5>
            </div>
          </b-card>
        </div>
        <div class="col-12" v-else>
          <b-card class="mb-0">
            <div class="d-flex justify-content-start align-items-center">
              <h5 class="mb-0">
                No results found for "{{ search_text }}"
              </h5>
            </div>
          </b-card>
        </div>
      </template>
    </b-row>

    <div class="text-center py-5" v-if="request.status === 'loading'">
      <b-spinner label="Spinning" variant="primary"></b-spinner>
    </div>

    <div class="text-center py-5" v-if="request.status === 'error'">
      <b-alert show variant="danger">
        <i class="ri-error-warning-line me-2 align-middle"></i>
        <b>Error while loading the contracts</b>
      </b-alert>
    </div>


    <ContractCreateModal
        ref="contractCreateModal"
        @create="getContracts"
        :currencies="currencies"
    />
    <ContractUpdateModal
        ref="contractUpdateModal"
        @updated="getContracts"
        :currencies="currencies"
    />
  </div>
</template>


<style scoped>
.scale-animation, .contract-card {
  transition: all 0.1s ease-in-out;
}

.scale-animation:hover {
  transform: scale(1.01);
}

.scale-animation:hover .contract-card {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}
</style>
