<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  emits: ['invoiceDeleted'],
  props: {
    invoices_list: {
      type: Array,
      required: true
    },
    order_number: {
      type: Number,
      required: true
    }
  },
  computed: {
    invoice_in_query() {
      return this.$route.query.invoice
    }
  },
  methods: {
    async downloadFile(url_without_base) {
      let url = process.env.VUE_APP_ORDER_URL + url_without_base
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
    deleteInvoiceConfirmation(invoice) {
      let invoice_name = invoice.file.split('/').pop().split('.')[0]
      Swal.fire({
        position: "center",
        icon: "error",
        html: `
            <h3>Are you sure that <br> you want to delete this invoice </h3>
        `,
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Delete It',
        denyButtonText: 'Cancel',
        cancelButtonColor: 'transparent',
        focusConfirm: false,
        inputLabel: `Please type \n ${invoice_name.trim()} to confirm`,
        input: 'text',
        inputPlaceholder: invoice_name,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === invoice_name.trim()) {
              resolve(this.deleteInvoice(invoice))
            } else {
              resolve('Confirmation did not match :)')
            }
          })
        }
      });
    },
    async deleteInvoice(invoice) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      try {
        await axios.delete(`/invoice/delete/${invoice.id}/`)
        this.$emit('invoiceDeleted', invoice)
        await Toast.fire({
          icon: "success",
          title: "Invoice deleted successfully",
        });
      } catch {
        await Toast.fire({
          icon: "error",
          title: "Something went wrong",
        });
      }
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="card-header align-items-center d-flex border-bottom-dashed">
      <h4 class="card-title mb-0 flex-grow-1">Invoices</h4>
      <div class="flex-shrink-0" v-if="order_number && invoices_list.length === 0">
        <router-link :to="{ name: 'wagon_invoice_create', params: {order_number: order_number}}">
          <button type="button" class="btn btn-outline-success">
            <i class="bx bx-plus-medical me-1 align-middle"></i>
            Create
          </button>
        </router-link>
      </div>
    </div>

    <div class="card-body p-0 pb-2" v-if="invoices_list.length > 0">

      <TransitionGroup name="list" tag="ul" class="list-unstyled mb-0">
        <li class="px-3 py-2 mt-1"
            v-for="invoice in invoices_list" :key="invoice.id"
            :class="{'bg-soft-success': invoice_in_query === invoice.id}"
        >
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0 me-3">
              <div class="avatar-sm">
                <div class="avatar-title bg-light text-secondary rounded fs-24">
                  <i class="bx bx-file"></i>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 overflow-hidden">
              <h5 class="fs-13 mb-1">
                <a class="text-body text-truncate d-block">
                  {{ invoice.file.split('/').pop() }}
                </a>
              </h5>
              <div>
                <span>
                  {{ invoice.file_size }}
                </span>
              </div>
            </div>
            <div class="flex-shrink-0 ms-2">
              <i @click="downloadFile(invoice.file)" class="bx bxs-download c_icon_hoverable fs-18"></i>
              <i @click="deleteInvoiceConfirmation(invoice)"
                 class="bx bxs-trash-alt c_icon_hoverable fs-18 ms-2 text-danger"></i>
            </div>
          </div>
        </li>
      </TransitionGroup>

    </div>

    <template v-else>
      <div class="py-5 text-center">
        <h5 class="card-title">
          This order has no invoices yet
        </h5>
        <p class="card-text text-muted">
          Create a new invoice to see it here
        </p>
      </div>
    </template>

  </div>
</template>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

</style>