<script>
import {defineComponent} from 'vue'
import Swal from "sweetalert2";
import axios from "axios";
import store from "@/state/store";

export default defineComponent({
  name: "InvoiceList",
  emits: ['invoiceDeleted'],
  props: {
    order_number: {
      type: Number,
      required: true
    },
    order_invoices: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  computed: {
    user() {
      return store.state.user;
    },
    invoices: {
      get() {
        return this.order_invoices ? this.order_invoices : []
      }
    },
    invoice_in_query() {
      return this.$route.query.invoice
    }
  },
  methods: {
    invoiceName(invoice_path) {
      if (!invoice_path) return '-'
      return invoice_path.split('/').pop()
    },
    invoiceFileSize(file_size) {
      return (file_size / 1024 / 1024).toFixed(2) + ' MB'
    },
    downloadFile(invoice_path) {
      let url = process.env.VUE_APP_ORDER_URL + invoice_path
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file'));
    },
    deleteInvoiceConfirmation(invoice) {
      let invoice_name = this.invoiceName(invoice.file).split('.')[0]
      Swal.fire({
        position: "center",
        icon: "error",
        html: `
            <h3>Are you sure that <br> you want to delete this invoice </h3>
        `,
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Delete It',
        denyButtonText: 'Cancel',
        cancelButtonColor: 'transparent',
        focusConfirm: false,
        inputLabel: `Please type ${invoice_name.trim()} to confirm`,
        input: 'text',
        inputPlaceholder: invoice_name,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === invoice_name.trim()) {
              resolve(this.deleteInvoice(invoice))
            } else {
              resolve('Confirmation did not match :)')
            }
          })
        }
      });
    },
    async deleteInvoice(invoice) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      try {
        await axios.delete(`/invoice/delete/${invoice.id}/`)
        this.$emit('invoiceDeleted', invoice)
        await Toast.fire({
          icon: "success",
          title: "Invoice deleted successfully",
        });
      } catch {
        await Toast.fire({
          icon: "error",
          title: "Something went wrong",
        });
      }
    }
  }
})
</script>

<template>
  <b-card no-body>
    <b-card-header class="d-flex align-items-center" :class="{
      'justify-content-between' : invoices.length === 0,
    }">
      <h5 class="mb-0">Invoices</h5>
      <router-link
          v-if="invoices.length === 0"
          :to="`/invoices/create/container/${order_number}/`">
        <b-button variant="outline-success" class="float-end">
          <i class="bx bx-plus-medical me-1"></i> Create Invoice
        </b-button>
      </router-link>
    </b-card-header>
    <b-card-body class="py-0 px-0">

      <TransitionGroup v-if="invoices.length > 0" name="list" tag="ul" class="list-unstyled mb-0">

        <li class="py-2 my-1 border-bottom px-3"
            v-for="invoice in invoices" :key="invoice.id"
            :class="{'bg-soft-success': invoice_in_query === invoice.id}"
        >
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0 me-3">
              <div class="avatar-xs">
                <div class="avatar-title bg-light text-secondary rounded fs-16 border">
                  <i class="bx bx-file"></i>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 overflow-hidden">
              <h6 class="mb-1 fs-12">
                <a class="text-body text-truncate d-block">
                  {{ invoiceName(invoice.file) }}
                </a>
              </h6>
              <div>
                <span>{{ invoiceFileSize(invoice.file_size) }}</span>
              </div>
            </div>
            <div class="flex-shrink-0 ms-2">
              <i @click="downloadFile(invoice.file)"
                 class="bx bxs-download c_icon_hoverable fs-18"></i>
              <i @click="deleteInvoiceConfirmation(invoice)"
                 v-if="user.role.toLowerCase() === 'admin'"
                 class="bx bxs-trash-alt c_icon_hoverable fs-18 ms-2 text-danger"></i>
            </div>
          </div>
        </li>
      </TransitionGroup>

      <template v-else>
        <div class="py-5 text-center">
          <h5 class="card-title">
            This order has no invoices yet
          </h5>
          <p class="card-text text-muted">
            Create a new invoice to see it here
          </p>
        </div>
      </template>

    </b-card-body>
  </b-card>
</template>


<style>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>