<script>
import axios from "axios";
import {useRoute} from "vue-router";
import numberToWordsRu from "number-to-words-ru"
import Swal from "sweetalert2";
import moment from "moment/moment";
import Currencies from "@/core/Currencies";

export default {
  data() {
    let route = useRoute()
    return {
      route: route,
      order: null,
      agreed_rates: [],
      additional_costs: [],
      Currencies: Currencies,

      invoice: {
        date: new Date().toJSON().slice(0, 10),
        contract_id: null,
        total_price_in_text: ''
      }
    };
  },
  computed: {
    order_number() {
      return this.route.params.order_number
    },
    invoice_total_price() {
      return this.agreed_rates.reduce((sum, item) => sum + item.total_agreed_rate, 0)
          + this.additional_costs.reduce((sum, item) => sum + item.total_additional_cost, 0)
    },
    form_is_valid() {
      return this.invoice.contract_id !== null
          && this.agreed_rates.every(item => item.service_name.trim().length > 0)
          && this.additional_costs.every(item => item.service_name.trim().length > 0)
    },
    order_currency_icon() {
      if (!this.order) return "$"
      try {
        return this.Currencies.find(i => i.value === this.order.currency).icon
      } catch {
        return "$"
      }
    }
  },
  methods: {
    async getOrder() {
      try {
        let response = await axios.get(
            `/wagon_order/info_for_invoice/${this.order_number}/`
        );
        this.order = response.data;

        if ((this.order.contracts || []).length === 1) {
          this.invoice.contract_id = this.order.contracts[0].id ? this.order.contracts[0].id : null
        }

        if (this.order.is_rented) {
          this.agreed_rates = (this.order.freight_charges || []).map((i) => {
            return {
              ...i,
              service_name: i.wagon__name || '',
              quantity: i.actual_weight ? i.actual_weight : 0,
              agreed_rate: i.agreed_rate_per_tonn ? parseFloat(i.agreed_rate_per_tonn) : 0,
              total_agreed_rate: parseFloat(i.agreed_rate_per_tonn || 0) * (i.actual_weight || 0)
            }
          })
        } else {
          this.agreed_rates = (this.order.freight_charges.agreed_rate_per_tonn || []).map((i) => {
            return {
              ...i,
              service_name: 'Транспортное обслуживание',
              quantity: i.quantity ? i.quantity : 0,
              agreed_rate: i.agreed_rate_per_tonn ? parseFloat(i.agreed_rate_per_tonn) : 0,
              total_agreed_rate: parseFloat(i.agreed_rate_per_tonn || 0) * (i.quantity || 0)
            }
          })
          this.additional_costs = (this.order.freight_charges.additional_cost || []).map((i) => {
            return {
              ...i,
              service_name: 'Транспортное обслуживание',
              quantity: i.quantity ? i.quantity : 0,
              additional_cost: i.additional_cost ? parseFloat(i.additional_cost) : 0,
              total_additional_cost: parseFloat(i.additional_cost || 0) * (i.quantity || 0)
            }
          })
        }
      } catch {
        alert("Couldn't not get order data");
      }
    },
    async downloadInvoice() {
      if (!this.form_is_valid) return alert("Invalid form!")
      try {
        let items = this.agreed_rates.map((i) => {
          return {
            service: i.service_name,
            quantity: i.quantity,
            amount: i.agreed_rate,
            total: parseFloat(i.total_agreed_rate).toFixed(2),
          };
        });
        let additional_costs = this.additional_costs.map((i) => {
          return {
            quantity: i.quantity,
            amount: i.additional_cost,
            total: parseFloat(i.total_additional_cost).toFixed(2),
            service: i.service_name,
          };
        });

        let response = await axios.post('/invoice/create/', {
          order_number: this.route.params.order_number,
          items: items,
          add_charges: additional_costs,
          contract_id: this.invoice.contract_id,
          date: moment(this.invoice.date).format('YYYY-MM-DD'),
          text_price: this.invoice.total_price_in_text
        })

        let url = process.env.VUE_APP_ORDER_URL + response.data.file;
        fetch(url)
            .then((resp) => resp.blob())
            .then((blobobject) => {
              const blob = window.URL.createObjectURL(blobobject);
              const anchor = document.createElement("a");
              anchor.style.display = "none";
              anchor.href = blob;
              anchor.download = url.toString().split("/")[
              url.toString().split("/").length - 1
                  ];
              document.body.appendChild(anchor);
              anchor.click();
              window.URL.revokeObjectURL(blob);

            })
            .catch(() => alert("An error in downloading the file sorry"));

        await Swal.fire({
          icon: "success",
          title: "Invoice downloaded successfully",
          text: "You will be redirected to the order details page",
        });

        await this.$router.push({
          name: "orders_wagon_detail",
          params: {id: this.order_number},
          query: {invoice: response.data.invoice},
        });
      } catch {
        alert("error")
      }
    },
    getTotalPriceInWord() {
      try {
        return numberToWordsRu.convert(parseFloat(this.invoice_total_price), {
          currency: "usd"
        })
      } catch {
        return 'Failed to convert number to text ('
      }
    }
  },
  watch: {
    invoice_total_price: {
      handler() {
        this.invoice.total_price_in_text = this.getTotalPriceInWord()
      }
    }
  },
  async mounted() {
    if (this.order_number) {
      await this.getOrder();
    } else {
      alert("Order not found")
    }
  },
};
</script>

<template>
  <div class="row justify-content-center align-items-center">
    <div class="col-xl-12 col-xxl-9" style="max-width: 1250px" v-if="order">

      <div v-if="order.contracts.length > 0" class="card">
        <div class="card-body border-bottom border-bottom-dashed p-4 pb-2">

          <div class="row justify-content-between mb-5">
            <div class="col-lg-4">
              <h4 class="text-dark fw-semibold">FE LLC InterRail CA</h4>
              <h6 class="fs-14">Rakatboshi street, 2,</h6>
              <h6 class="fs-14">Yakkasaroy district</h6>
              <h6 class="fs-14">100031 Tashkent, Uzbekistan</h6>
              <h6 class="fs-14">Tel/Fax: +998 55 5065600</h6>
              <h6 class="fs-14">Website: <a href="https://www.interrail.ag">www.interrail.ag</a></h6>
            </div>
            <div class="col-lg-5 text-end">
              <img
                  alt="Responsive image"
                  class="img-fluid"
                  src="@/assets/images/interrail-logo.png"
              />
            </div>
          </div>

          <div class="text-center d-flex flex-column justify-content-center gap-2 align-items-center mb-5">
            <div>
              <pre class="fs-18 fw-bolder mb-0">СЧЕТ на оплату</pre>
            </div>
            <div class="d-flex align-items-center gap-1">
              <div>
                <pre class="mb-0 fs-18 fw-bolder">по договору /</pre>
              </div>
              <select v-model="invoice.contract_id" class="form-select p-0 border-0 fs-17 fw-semibold" :class="{
                'text-muted': !invoice.contract_id,
                'text-dark': invoice.contract_id,
              }">
                <option selected disabled :value="null" class="text-muted">Select contract</option>
                <option v-for="contract in order.contracts"
                        :key="`order_contract_${contract.id}`"
                        :value="contract.id"
                >
                  {{ contract.name }} от {{ contract.start_date || '***' }}
                </option>
              </select>
            </div>
          </div>

          <div class="table-responsive mb-4">
            <table class="table table-bordered table-wrap">
              <thead>
              <tr>
                <th class="w-50" scope="col">
                  <pre class="mb-0 fs-15">ЭКСПЕДИТОР / FORWARDER</pre>
                </th>
                <th class="w-50" scope="col">
                  <pre class="mb-0 fs-15">ЗАКАЗЧИК</pre>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">
                  <pre class="mb-0 fs-15">
FE LLC "INTERRAIL CA"
Legal address: Tashkent city, Yakkasaray district,
Yunus Rajabi MFY, Rakatboshi street, Building 2
Tel: +998946493626
TIN: 310150204 OKPO: 32451402 OKED: 52291
MFO: 01136
Bank: JSCB "Kapitalbank" Yashnabad branch of Tashkent
SWIFT: KACHUZ22
Settlement account (USD): 20214840405604029001</pre>
                </th>
                <th>
                  <pre class="mb-0 fs-15">
{{ order.company.invoice_info }}</pre>
                </th>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="table-responsive">
            <table class="table table-bordered table-wrap">
              <thead>
              <tr class="align-middle text-center">
                <th style="min-width: 75px" rowspan="2" scope="col">
                  № <br>
                  п/п
                </th>
                <th rowspan="2">
                  Наименование Товаров (работ, услуг).
                  <br> Type of service
                </th>
                <th rowspan="2">ед.изм./ Currency</th>
                <th rowspan="2">вес / Wt</th>
                <th rowspan="2">Цена Rate</th>
                <th rowspan="2">Стоимость Поставки /delivery</th>
                <th colspan="2"></th>
                <th colspan="2" class="py-5">НДС / VAT</th>
              </tr>
              <tr class="align-middle text-center">
                <th class="py-4">Ставка Rate</th>
                <th>Сумма Amount</th>
                <th>Ставка Rate</th>
                <th>Сумма Amount</th>
              </tr>
              <tr class="align-middle text-center">
                <template v-for="number in 10" :key="`number_${number}`">
                  <td class="py-3">{{ number }}</td>
                </template>
              </tr>
              </thead>
              <tbody>
              <tr class="align-middle text-center">
                <td></td>
                <td colspan="9" class="text-start py-3">транспортно-экспедиторского обслуживания/Freight Forwarding
                  Service
                </td>
              </tr>
              <template v-for="(service, i) in agreed_rates" :key="`service_${service}`">
                <tr class="align-middle text-center">
                  <td>{{ i + 1 }}</td>
                  <td class="px-1" style="min-width: 130px">
                    <input v-model="service.service_name" class="form-control border-0"
                           :placeholder="order.is_rented ? 'Номер вагона' : `Наименование услуги`">
                  </td>
                  <td>Дол.США / USD</td>
                  <td>{{ service.quantity }}</td>
                  <td style="min-width: 100px">
                    {{ order_currency_icon }} {{
                      parseFloat(service.agreed_rate).toLocaleString(undefined, {
                        minimumFractionDigits: 2, maximumFractionDigits: 2
                      })
                    }}
                  </td>
                  <td>
                    {{ order_currency_icon }} {{
                      parseFloat(service.total_agreed_rate).toLocaleString(undefined, {
                        minimumFractionDigits: 2, maximumFractionDigits: 2
                      })
                    }}
                  </td>
                  <td v-if="i === 0" :rowspan="additional_costs.length + agreed_rates.length" colspan="2">
                    Без акциза<br>
                    without excise
                  </td>
                  <td v-if="i === 0" :rowspan="additional_costs.length + agreed_rates.length" colspan="2">
                    Без НДС<br>
                    without VAT
                  </td>
                </tr>
              </template>
              <template v-for="(service, i) in additional_costs" :key="`service_${service}`">
                <tr class="align-middle text-center">
                  <td>{{ agreed_rates.length + i + 1 }}</td>
                  <td class="p-0" style="min-width: 130px">
                    <input v-model="service.service_name" class="form-control border-0"
                           placeholder="Наименование услуги">
                  </td>
                  <td>Дол.США / USD</td>
                  <td>{{ service.quantity }}</td>
                  <td>
                    {{ order_currency_icon }} {{
                      service.additional_cost.toLocaleString(undefined, {
                        minimumFractionDigits: 2, maximumFractionDigits: 2
                      })
                    }}
                  </td>
                  <td>
                    {{ order_currency_icon }} {{
                      service.total_additional_cost.toLocaleString(undefined, {
                        minimumFractionDigits: 2, maximumFractionDigits: 2
                      })
                    }}
                  </td>
                </tr>
              </template>
              <tr>
                <th colspan="11"></th>
              </tr>
              <tr class="align-middle text-center">
                <th colspan="5">
                  Всего к оплате / Total for payment:
                </th>
                <th>
                  {{ order_currency_icon }} {{
                    invoice_total_price.toLocaleString(undefined, {
                      minimumFractionDigits: 2, maximumFractionDigits: 2
                    })
                  }}
                </th>
                <th colspan="2">Без акциза <br>without excise</th>
                <th colspan="2"></th>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="d-flex flex-column gap-3 fs-14 my-2">
            <div class="d-flex align-items-center gap-4">
              <span>Всего на сумму / Total amount:</span> <b>
              {{ order_currency_icon }} {{
                invoice_total_price.toLocaleString(undefined, {
                  minimumFractionDigits: 2, maximumFractionDigits: 2
                })
              }}</b>
            </div>
            <div style="color: red">
              {{ invoice.total_price_in_text }}
            </div>
            <div>
              <h5 class="text-dark fw-semibold mb-0">FE LLC "INTERRAIL CA"</h5>
            </div>
            <div>
              Руководитель___________________
            </div>
            <div>
              Director:
            </div>
            <div class="mt-2">
              <button @click="downloadInvoice()" :disabled="!form_is_valid"
                      class="btn btn-success btn-lg w-100">Submit & Download Invoice
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="card card-body text-center mt-5 py-5 pb-4 px-4">
        <div class="avatar-xl mx-auto mb-4">
          <div class="avatar-title bg-soft-danger text-danger fs-17 rounded">
            <i class="ri-spam-line display-4"></i>
          </div>
        </div>
        <h2 class="card-title fs-18 mb-3 mt-2">Invoice Generation Unavailable</h2>
        <p class="card-text text-muted fs-16 lh-lg">
          No contract with {{ order.company.name }} has not been created yet.<br>
          Please
          <router-link :to="{
            name: 'customer_profile',
            params: {
              slug: order.company.slug,
            },
            query: {
              open_contract_create_modal: true,
              redirect_success: `/invoices/create/container/${order_number}/`
            }
          }" class="text-success">create a contract
          </router-link>
          before attempting to generate an invoice.
        </p>
        <div>
          <router-link :to="{
            name: 'customer_profile',
            params: {
              slug: order.company.slug,
            },
            query: {
              open_contract_create_modal: true,
              redirect_success: `/invoices/create/container/${order_number}/`
            }
          }"
                       class="btn btn-success btn-lg"
          >Go to Contracts
          </router-link>
        </div>

      </div>

    </div>
  </div>
</template>

<style scoped>
/* Remove default arrow */
select.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
}

/* For IE10 and IE11 */
select.form-select::-ms-expand {
  display: none;
}
</style>
